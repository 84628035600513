import Vue from 'vue'
import Axios from 'axios'
import Qs from 'qs'
import $store from '../store'
import $router from '../router'
const axiVue = new Vue()
const service = Axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL
})

let isLoading = null

// 基础请求函数
const baseService = (options) => {
	let token = $store.state.app.token
	if (options.isLogin && !token) {

	}
	if (options.method === 'post' || options.method === 'put' || options.method === 'patch') {
		options.data.token = token
	} else {
		options.params.token = token
	}
	if (options.loading && options.loading === true) {
		isLoading = axiVue.$loading({
			lock: true,
			text: 'Loading',
			background: 'rgba(0, 0, 0, 0.6)'
		})
	}
	return service(options)
}

// 请求拦截器
service.interceptors.request.use(
	config => {
		if (config.method === 'post') {
			config.data = Qs.stringify(config.data)
		}

		// 提现用的这个
		// if(process.env.NODE_ENV !== 'development') {
		// 	if(config.url === '/master/getWithdraw') {
		// 		config.baseURL = process.env.API_ROOT5
		// 	}
		// }

		return config
	},
	error => {
		return Promise.reject(error).catch(e => {
			console.log(e)
		})
	}
)
// 响应拦截器
service.interceptors.response.use(
	res => {
		if (isLoading) isLoading.close()
		if (res.data.code === 400 || res.data.code === 201) {
			localStorage.clear()
			alert('会话已过期，请重新登录')
			window.location.href = '/#/login'
		}
		return Promise.resolve(res.data)
	},
	error => {
		if (isLoading) isLoading.close()
		let result = {}
		if (error && error.response) {
			result.code = error.response.status
			switch (error.response.status) {
				case 400:
					result.msg = '错误请求'
					break
				case 401:
					result.msg = '未授权，请重新登录'
					break
				case 403:
					result.msg = '拒绝访问'
					break
				case 404:
					result.msg = '请求错误,未找到该资源'
					break
				case 405:
					result.msg = '请求方法未允许'
					break
				case 408:
					result.msg = '请求超时'
					break
				case 500:
					result.msg = '服务器端出错'
					break
				case 501:
					result.msg = '网络未实现'
					break
				case 502:
					result.msg = '网络错误'
					break
				case 503:
					result.msg = '服务不可用'
					break
				case 504:
					result.msg = '网络超时'
					break
				case 505:
					result.msg = 'http版本不支持该请求'
					break
				default:
					result.msg = `连接错误${error.response.status}`
			}
		} else {
			result.code = -1
			result.msg = '未知错误'
		}
		return Promise.reject(result).catch(e => {
			return e
		})
	}
)

const $http = ['post', 'put', 'patch'].reduce((request, method) => {
	/**
	 * @param url string 接口地址
	 * @param data object get参数
	 * @param options object axios 配置项
	 * @returns {AxiosPromise}
	 */
	request[method] = (url, data = {}, options = {}) => {
		return baseService(
			Object.assign({ url, data, method }, options)
		)
	}
	return request
}, {});

['get', 'delete', 'head'].forEach(method => {
	/**
	 * @param url string 接口地址
	 * @param params object get参数
	 * @param options object axios 配置项
	 * @returns {AxiosPromise}
	 */
	$http[method] = (url, params = {}, options = {}) => {
		return baseService(
			Object.assign({ url, params, method }, options)
		)
	}
})

export default $http
